import React, { Component } from "react";
import { Link } from "gatsby";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

const PasswordForgetPage = () => (
  <div>
    <PasswordForgetForm />
  </div>
);

const INITIAL_STATE = {
  email: "",
  error: null,
  sent: false,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ sent: true });
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error, sent } = this.state;

    const isInvalid = email === "";

    if (sent) {
      return <span>Check your email ({email}) to set your new password.</span>;
    }

    if (error) {
      return <span>An error occurred: {error}</span>;
    }

    return (
      <form onSubmit={this.onSubmit}>
        <input
          name="email"
          value={this.state.email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
        />
        <button className="fancy" disabled={isInvalid} type="submit">
          Reset My Password
        </button>

        {error && <p className="field-error">{error.message}</p>}
      </form>
    );
  }
}

const PasswordForgetLink = () => (
  <span className="forgot-password-link">
    <Link className="light-link" to={ROUTES.PASSWORD_FORGET}>
      <span>Forgot</span> Password?
    </Link>
  </span>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
