import React from "react";

import Layout from "../../components/Layout";
import PasswordForgetForm from "../../components/PasswordForget";

const PasswordForgetPage = () => (
  <section className="section section--gradient">
    <div className="container">
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="section">
            <h1>Password Reset</h1>
            <PasswordForgetForm />
          </div>
        </div>
      </div>
    </div>
  </section>
);

const PasswordForgetFinal = () => (
  <Layout>
    <PasswordForgetPage />
  </Layout>
);

export default PasswordForgetFinal;
